import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {StartComponent} from "./pages/start/start.component";
import {KontaktComponent} from "./pages/kontakt/kontakt.component";
import {ImpressumComponent} from "./pages/impressum/impressum.component";
import {DatenschutzComponent} from "./pages/datenschutz/datenschutz.component";

const routes: Routes = [
  { path: '', component: StartComponent},
  { path: 'kontakt', component: KontaktComponent},
  { path: 'impressum', component: ImpressumComponent},
  { path: 'datenschutz', component: DatenschutzComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
